.btn-primary {
    border-radius: 4px;
    background: #034EA2;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
}

.btn-primary:hover {
    background: #034EA2;
}

.btn-primary-outline {
    border-radius: 4px;
    border: 2px solid #034EA2;
    background: #FFF;
    color: #034EA2;
    font-size: 20px;
    font-weight: 500;
}

.btn-primary-outline:hover {
    border: 2px solid #034EA2;
    background: #FFF;
    color: #034EA2;
}

.btn-app {
    width: 162px !important;
    height: 46px !important;
    flex-shrink: 0;
}

.btn-tab {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    color: #034EA2;
    font-size: 20px;
    height: 58px;
    flex-shrink: 0;
}

.btn-back {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    color: #034EA2;
    font-size: 20px;
    font-weight: 500;
    width: 211px;
    height: 62px;
    flex-shrink: 0;
}

.spinner-showing{
    color: #FFFFFF;
}