/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~highlight.js/styles/github.css";
@import "assets/css/custom/style-custom.css";
@import "assets/css/custom/style-icon-custom.css";


/* For main pulapulate control wrapper web page */
html {
  overflow: auto;
}

@font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-Regular.ttf) format("truetype");
}

/* @font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-Medium.ttf) format("truetype");
  font-weight: 700;
} */

@font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-Thin.ttf) format("truetype");
  font-weight: 100;
}

/* @font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-Bold.ttf) format("truetype");
  font-weight: bold;
} */

/* @font-face {
  font-family: 'Kanit';
  src: url(./assets/fonts/Kanit/Kanit-ExtraBold.ttf) format("truetype");
  font-weight: bolder;
} */

body {
  /* height: 100vh !important; */
  min-width: 351px !important;
  font-family: "Kanit", "Roboto", "Helvetica Neue", "Arial", "sans-serif" !important;
  font-size: 14px;
  font-weight: 400;
}

.wrapper {
  height: 100vh;
  min-height: 100vh !important;
  position: relative;
  padding-top: 0px !important;
}

/* For control loader spinner */
.loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For control toast on top */
#toast-container>div {
  opacity: 1;
}

/* For control form-control custom myself ignore css on theme */
.table tbody tr td {
  padding: 0px 10px !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-group>label {
  font-weight: bold;
}

.form-control:disabled.form-control:not([readonly]) {
  color: #666666 !important;
  background-color: #cccccc !important;
  border: 1px solid #999999 !important;
}

.form-group.float-label .form-control {
  background-color: transparent !important;
}

/* For control custom style bs-datepicker */
.custom-today-class {
  background-color: #252d47;
}

.bs-datepicker-container {
  /* background-color: #624033 !important; */
}

.bs-datepicker-head {
  background-color: #957266 !important;
}

/* For scheduler border (auto) */
fieldset.scheduler-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 0.3em 1.4em !important;
  margin: 0;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.scheduler-border {
  width: inherit;
  padding: 0 10px;
  border-bottom: none;
  font-size: 0.75rem;
  font-weight: bold;
}

/* For progress bar */
.progress {
  margin-bottom: 0rem !important;
}

/* For customs color bg/txt/link/a/etc. */
.bg-light a {
  color: #ffffff !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: #ffffff !important;
}

.breadcrumb-item.active {
  color: #cfcfcf !important;
}

/* For sidebar custom color style */
body .wrapper .sidebar .nav:not(.nav-tabs) li a.active {
  background-color: #fff3cd !important;
  color: #252d47;
}

body .wrapper .sidebar .nav:not(.nav-tabs) li a:hover {
  border-left: 3px solid #fff3cd !important;
  padding-left: 12px;
}

/* For header custom color style */
body .wrapper>header .menu-btn:hover,
body .wrapper .header .menu-btn:hover {
  background-color: #fff3cd !important;
  color: #252d47 !important;
}

/* For table hover */
.table tbody tr:hover td:first-child:before {
  background: #fff3cd !important;
}

/* For modal custom size */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-content {
  border: 1px solid;
}

/* For custom label size sm */
.col-form-label-sm {
  font-size: 0.65rem !important;
}

/* For custom input layout */

input[type=radio] {
  width: auto;
}

/* For customize button myself */
.btn.disabled,
.btn:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* For ngx-typeahead customize */
typeahead-container.dropdown-menu .dropdown-item {
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
}

typeahead-container.dropdown-menu .dropdown-item:hover {
  background-color: #4caf50 !important;
  border-left: 3px solid #fff !important;
  padding-left: 12px;
}

typeahead-container.dropdown-menu .dropdown-item:active {
  background: #4caf50 !important;
}

typeahead-container.dropdown-menu .dropdown-item:active i {
  color: #fff !important;
}

typeahead-container.dropdown-menu {
  padding: 0px;
  background-color: #343a40;
}

typeahead-container.dropdown-menu .dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #4caf50;
}

select.form-control {
  height: 38px !important;
}

.pointer {
  cursor: pointer;
}

.error-message-form-validate {
  color: #FFFFFF !important;
}

.invalid-tooltip {
  position: initial;
  margin-bottom: 20px;
}

.g2n-modal-width.modal-dialog {
  max-width: 1808px !important; /* Customize the width as per your needs */
  width: 95%;
}

.btn-close.close {
  width: 19px;
  height: 19px;
  flex-shrink: 0;
}

.card, .card > .card-body {
  /* background-color: #fff; */
  border-radius: 8px;
  /* color: #034EA2 !important; */
}

.e-guarantee-lable {
  /* background-color: #fff; */
 
  color: #034EA2 !important;
  font-size: 16px !important;
}

.e-guarantee-table {
  color: #034EA2 !important;
  font-size: 20px !important;
  font-weight: 500;
}
