body {
  height: 100%;
  position: relative;
  display: block
}

app-root {
  height: 100%;
  position: relative;
  display: block
}

app-layout {
  height: 100%;
  position: relative;
  display: block
}

body .wrapper {
  height: auto;
  min-height: 100%;
  padding-bottom: 60px;
}

body .footer {
  margin-top: -50px
}

app-login {
  height: 100%;
  display: block;
}

app-signup {
  height: 100%;
  display: block;
}

app-not-found {
  height: 100%;
  display: block;
}
